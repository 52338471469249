import firebase from './firebase'
import authReducer from './authReducer'
import Auth from './Auth'

export default firebase

export {
  authReducer,
  Auth,
  // ENV_COLLECTIONS,
}
