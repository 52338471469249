import firebase from 'firebase/app'

/**
 * 1. Put firebase credentials in .env file to the root of the project.
 * 2. require('dotenv').config({ path: `.env` }) in the beginning of gatsby-config.js
 * 3. import 'firebase/auth' – auth or other services inside gatsby-browser.js and gatsby-ssr.js
 * 4. Set your settings below in the if-statement (like the region for the functions for example).
 * 5. Call firebase services inside components like so: firebase.auth().onAuthStateChanged(args)
 */

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(config)
  firebase.functions = () => firebase.app().functions('europe-west1')
}

export default firebase
